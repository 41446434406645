import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyDR1EJrIbnTeZvBuVdP_yRSoHopUfnVh08",
    authDomain: "ritualgrowth.firebaseapp.com",
    databaseURL: "https://ritualgrowth-default-rtdb.firebaseio.com",
    projectId: "ritualgrowth",
    storageBucket: "ritualgrowth.appspot.com",
    messagingSenderId: "489614074563",
    appId: "1:489614074563:web:f4917b5cd07bd90b037876",
    measurementId: "G-RYFF085CXT"
  };

  export const firebaseApp = initializeApp(firebaseConfig);
  
 